const colibri = {
    slideUp: (element, duration = 1000) => {
        element.style.height = element.offsetHeight + 'px';

        element.style.transitionProperty = 'height, padding, margin';
        element.style.transitionDuration = duration + 'ms';

        // WTF?!
        element.offsetHeight;

        element.style.overflow = 'hidden';
        element.style.height = 0;
        element.style.paddingTop = 0;
        element.style.paddingBottom = 0;
        element.style.marginTop = 0;
        element.style.marginBottom = 0;

        window.setTimeout(() => {
            element.style.display = 'none';

            element.style.removeProperty('height');
            element.style.removeProperty('overflow');
            element.style.removeProperty('padding-top');
            element.style.removeProperty('padding-bottom');
            element.style.removeProperty('margin-top');
            element.style.removeProperty('margin-bottom');
            element.style.removeProperty('transition-duration');
            element.style.removeProperty('transition-property');
        }, duration);
    },
    slideDown: (element, duration = 1000) => {
        element.style.removeProperty('display');

        let display = window.getComputedStyle(element).display;
        if (display === 'none')
            display = 'block';

        element.style.display = display;

        let height = element.offsetHeight;

        element.style.overflow = 'hidden';
        element.style.height = 0;
        element.style.paddingTop = 0;
        element.style.paddingBottom = 0;
        element.style.marginTop = 0;
        element.style.marginBottom = 0;

        // WTF?!
        element.offsetHeight;

        element.style.transitionProperty = 'height, margin, padding';
        element.style.transitionDuration = duration + 'ms';

        element.style.height = height + 'px';

        element.style.removeProperty('padding-top');
        element.style.removeProperty('padding-bottom');
        element.style.removeProperty('margin-top');
        element.style.removeProperty('margin-bottom');

        window.setTimeout(() => {
            element.style.removeProperty('height');
            // element.style.removeProperty('display');
            element.style.removeProperty('overflow');
            element.style.removeProperty('transition-duration');
            element.style.removeProperty('transition-property');
        }, duration);
    },
    slideToggle: (element, duration = 1000) => {
        if (window.getComputedStyle(element).display === 'none') {
            return slideDown(element, duration);
        } else {
            return slideUp(element, duration);
        }
    }
};

const animateCss = (node, animation, prefix = 'animate__') => {
    return new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`;

        node.classList.add(`${prefix}animated`, animationName);

        function handleAnimationEnd(event) {
            event.stopPropagation();
            node.classList.remove(`${prefix}animated`, animationName);
            resolve(node);
        }

        node.addEventListener('animationend', handleAnimationEnd, {once: true});
    });
}