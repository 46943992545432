const expands = document.querySelectorAll('.expand');

const expand = {
    close: (id) => {
        const root = document.querySelector(`#${id}`);

        const collapse = root.querySelector('.expand__collapse');
        collapse.style.display = 'none';
    },
    open: (id, html = null) => {
        expands.forEach(node => expand.close(node.id));
        const root = document.querySelector(`#${id}`);

        const collapse = root.querySelector('.expand__collapse');
        collapse.style.removeProperty('display');

        if (html)
            collapse.innerHTML = html;
    }
};

expands.forEach((node) => {
    expand.close(node.id);

    node.querySelector('.expand__toggler').addEventListener('click', () => {
        if (window.getComputedStyle(node.querySelector('.expand__collapse')).display === 'none')
            expand.open(node.id);
        else
            expand.close(node.id);
    });
});