document.querySelectorAll('.dropdown')
    .forEach((dropdown) => {
        const collapse = dropdown.querySelector('.dropdown__collapse');
        if (!(window.getComputedStyle(collapse).display === 'none'))
            collapse.style.display = 'none';

        const thumb = dropdown.querySelector('.dropdown__thumb');

        const open = (animation = 'fadeInUp') => {
            collapse.style.removeProperty('display');

            animateCss(collapse, animation);
            anime({
                targets: thumb,
                rotateZ: '180deg'
            });
        };

        const close = (animation = 'fadeOutUp') => {
            // animateCss(collapse, animation).then(result => collapse.style.display = 'none');

            collapse.style.display = 'none';
            anime({
                targets: thumb,
                rotateZ: '0deg'
            });
        };

        document.addEventListener('click', (event) => {
            if (event.target.closest('.dropdown') !== dropdown) {
                close();
            }
        });

        const toggler = dropdown.querySelector('.dropdown__toggler');
        toggler.addEventListener('click', () => {
            if (window.getComputedStyle(collapse).display === 'none')
                open();
            else
                close();
        });
    });
