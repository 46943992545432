document.querySelectorAll('.quantity')
    .forEach((quantity) => {
        const input = quantity.querySelector('input');
        input.addEventListener('change', () => {
            if (input.value < 1)
                input.value = 1;
        });

        quantity.querySelector('button:first-child')
            .addEventListener('click', () => {
                if (input.value > 1)
                    input.value--;
            });

        quantity.querySelector('button:last-child')
            .addEventListener('click', () => {
                    input.value++;
                }
            );
    });