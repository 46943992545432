document.querySelectorAll('.tabs')
    .forEach((tab) => {
        tab.querySelector('.tabs__head > .tabs__head-item:first-child').classList.add('tabs__head-item--active');
        tab.querySelector('.tabs__body > .tabs__body-item:first-child').classList.add('tabs__body-item--active');

        const heads = tab.querySelectorAll('.tabs__head-item');
        heads.forEach((item) => {
            item.addEventListener('click', function () {
                heads.forEach((item) => {
                    if (item.classList.contains('tabs__head-item--active'))
                        item.classList.remove('tabs__head-item--active');
                });

                tab.querySelectorAll('.tabs__body-item')
                    .forEach((item) => {
                        if (item.classList.contains('tabs__body-item--active'))
                            item.classList.remove('tabs__body-item--active');
                    });

                document.querySelector('.tabs__head > #' + this.id).classList.add('tabs__head-item--active');
                document.querySelector('.tabs__body > #' + this.id).classList.add('tabs__body-item--active');
            });
        });
    });