const msg = (string, type, timeout = 3000) => {
    switch (type) {
        case 'warning':
            type = 'msg--warning';
            break;

        case 'danger':
            type = 'msg--danger';
            break;

        default:
            type = 'msg--success';
    }

    const message = `<div class="msg ${type}"><span class="msg__thumb"></span><span class="msg__text">${string}</span></div>`;

    const alert = document.querySelector('.alert');
    alert.insertAdjacentHTML('afterbegin', message);

    const first = alert.querySelector('.msg:first-child');
    animateCss(first, 'fadeInRight').then(result => {
        setTimeout(() => animateCss(result, 'fadeOutRight').then(result => {
            result.remove();
        }), timeout);
    });
};

